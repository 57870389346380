// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blockchain-ask-tsx": () => import("./../../../src/pages/blockchain/ask.tsx" /* webpackChunkName: "component---src-pages-blockchain-ask-tsx" */),
  "component---src-pages-blockchain-index-tsx": () => import("./../../../src/pages/blockchain/index.tsx" /* webpackChunkName: "component---src-pages-blockchain-index-tsx" */),
  "component---src-pages-covid-19-ask-tsx": () => import("./../../../src/pages/covid-19/ask.tsx" /* webpackChunkName: "component---src-pages-covid-19-ask-tsx" */),
  "component---src-pages-covid-19-index-tsx": () => import("./../../../src/pages/covid-19/index.tsx" /* webpackChunkName: "component---src-pages-covid-19-index-tsx" */),
  "component---src-pages-covid-19-insights-tsx": () => import("./../../../src/pages/covid-19/insights.tsx" /* webpackChunkName: "component---src-pages-covid-19-insights-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-blockchain-all-intents-tsx": () => import("./../../../src/pages/internal/blockchain/all-intents.tsx" /* webpackChunkName: "component---src-pages-internal-blockchain-all-intents-tsx" */),
  "component---src-pages-internal-blockchain-create-intent-tsx": () => import("./../../../src/pages/internal/blockchain/create-intent.tsx" /* webpackChunkName: "component---src-pages-internal-blockchain-create-intent-tsx" */),
  "component---src-pages-internal-blockchain-edit-intent-tsx": () => import("./../../../src/pages/internal/blockchain/edit-intent.tsx" /* webpackChunkName: "component---src-pages-internal-blockchain-edit-intent-tsx" */),
  "component---src-pages-internal-covid-19-all-intents-tsx": () => import("./../../../src/pages/internal/covid-19/all-intents.tsx" /* webpackChunkName: "component---src-pages-internal-covid-19-all-intents-tsx" */),
  "component---src-pages-internal-covid-19-create-intent-tsx": () => import("./../../../src/pages/internal/covid-19/create-intent.tsx" /* webpackChunkName: "component---src-pages-internal-covid-19-create-intent-tsx" */),
  "component---src-pages-internal-covid-19-edit-intent-tsx": () => import("./../../../src/pages/internal/covid-19/edit-intent.tsx" /* webpackChunkName: "component---src-pages-internal-covid-19-edit-intent-tsx" */),
  "component---src-pages-internal-sign-in-tsx": () => import("./../../../src/pages/internal/sign-in.tsx" /* webpackChunkName: "component---src-pages-internal-sign-in-tsx" */),
  "component---src-pages-internal-topics-tsx": () => import("./../../../src/pages/internal/topics.tsx" /* webpackChunkName: "component---src-pages-internal-topics-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-suggest-question-and-answer-tsx": () => import("./../../../src/pages/suggest-question-and-answer.tsx" /* webpackChunkName: "component---src-pages-suggest-question-and-answer-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blockchain-blockchain-ask-intents-tsx": () => import("./../../../src/templates/blockchain/blockchain-ask-intents.tsx" /* webpackChunkName: "component---src-templates-blockchain-blockchain-ask-intents-tsx" */),
  "component---src-templates-blockchain-blockchain-category-tsx": () => import("./../../../src/templates/blockchain/blockchain-category.tsx" /* webpackChunkName: "component---src-templates-blockchain-blockchain-category-tsx" */),
  "component---src-templates-covid-19-covid-19-ask-countries-tsx": () => import("./../../../src/templates/covid-19/covid-19-ask-countries.tsx" /* webpackChunkName: "component---src-templates-covid-19-covid-19-ask-countries-tsx" */),
  "component---src-templates-covid-19-covid-19-ask-country-gov-res-timeline-tsx": () => import("./../../../src/templates/covid-19/covid-19-ask-country-gov-res-timeline.tsx" /* webpackChunkName: "component---src-templates-covid-19-covid-19-ask-country-gov-res-timeline-tsx" */),
  "component---src-templates-covid-19-covid-19-ask-intents-tsx": () => import("./../../../src/templates/covid-19/covid-19-ask-intents.tsx" /* webpackChunkName: "component---src-templates-covid-19-covid-19-ask-intents-tsx" */),
  "component---src-templates-covid-19-covid-19-category-tsx": () => import("./../../../src/templates/covid-19/covid-19-category.tsx" /* webpackChunkName: "component---src-templates-covid-19-covid-19-category-tsx" */)
}

